<script lang="ts">
import { defineComponent, ref } from "vue";


export default defineComponent({
    props: {
        images: Array,
        displayStyle: { type: String, default: 'default' }, // accepts: default | carousel | mobile | singleImage
        imageAlt: { type: String, default: 'image' },
        disableOnShow: { type: Boolean, default: false },
        showIndicators: { type: Boolean, default: false },
        arrows: { type: Boolean, default: false },
    },
    setup(props) {
        const visibleRef = ref(false);
        const indexRef = ref(0);

        const onShow = (index: number) => {
            if (props.disableOnShow) {
                return;
            }
            indexRef.value = index;
            visibleRef.value = true;
        };
        const onHide = () => (visibleRef.value = false);

        const carouselRef = ref()
        onMounted(async () => {
            setInterval(() => {
                if (!carouselRef.value) return

                if (carouselRef.value.page === carouselRef.value.pages) {
                    return carouselRef.value.select(0)
                }

                carouselRef.value.next()
            }, 3000)
        })

        return {
            carouselRef,
            onShow,
            onHide,
            indexRef,
            visibleRef,
        };
    },
});
</script>

<template>
    <template v-if="displayStyle === 'carousel'">
        <w-carousel-multi :items="images" @show="(v: any)=>{onShow(v)}"></w-carousel-multi>
    </template>
    <template v-else-if="displayStyle === 'carousel-compact'">
        <w-carousel-multi 
            :isHaveSlot="true"
            :items="images" 
            :imagesToShow="1"
            arrows 
            style="width: 12.5rem;"
            arrowWidth="8"
            
        >
            <template #item="{ item, index }">
                <img @click="() => onShow(index)" :src="`${item}?w=200&h=200`" :alt="imageAlt" class="object-cover h-48 w-48 me-2 rounded-lg cursor-pointer">
            </template>
        </w-carousel-multi>
    </template>

    <template v-else-if="displayStyle === 'mobile'">
        <w-carousel  :items="images"  @show="(v: any)=>{onShow(v)}"></w-carousel>
    </template>
    <template v-else-if="displayStyle === 'singleImage'">
        <div class="relative cursor-pointer" @click="() => onShow(0)">
            <span class="text-xs absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-10">Show</span>
            <div class="absolute inset-0 bg-gray-900 opacity-20 rounded-full"></div>
            <img :src="images[0]"class="rounded-full w-14 h-14" />
        </div>
    </template>
    <template v-else>
        <div class="col-span-2" @click="() => onShow(idx)" style="height:550px">
            <img :src="`${images[0]}?w=460&h=550`" alt="" class="object-cover h-5/6 w-full rounded">
        </div>
        <div>
            <div class="grid grid-cols-2 gap-2 overflow-auto h-5/6" style="height:460px;overflow-y:auto">
                <div v-for="(img, idx) in images" :key="idx" class="" @click="() => onShow(idx)">
                    <img :src="`${img}?w=150&h=150`" class="object-cover w-full rounded h-28" />
                </div>
            </div>
        </div>
    </template>

    <!-- <client-only> -->
        <vue-easy-lightbox :visible="visibleRef" :index="indexRef" :imgs="images" @hide="onHide">
            <template #next-btn="{ next }">
                <div @click="next" class="flex items-center justify-end p-3 btn__next"
                    style="height: 75%; width: 10rem;">
                    <svg class="vel-icon icon">
                        <use xlink:href="#icon-next"></use>
                    </svg>
                </div>
            </template>
            <template #prev-btn="{ prev }">
                <div @click="prev" class="flex items-center p-3 btn__prev" style="height: 75%; width: 10rem;">
                    <svg class="vel-icon icon">
                        <use xlink:href="#icon-prev"></use>
                    </svg>
                </div>
            </template>
        </vue-easy-lightbox>
    <!-- </client-only> -->
</template>

<style scoped>
.container {
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.gallery {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}
</style>