<script setup>
import { ref, defineProps, computed, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    required: true
  }
})

const currentIndex = ref(0)
const dragging = ref(false) // To track if the user is currently dragging
const dragTranslateX = ref(0) // Temporary translate value during drag
var transitionTime=0.5

// Compute the translateX value for sliding effect
const slideStyle = computed(() => {
  const baseTranslateX = -currentIndex.value * 100 // Base translate for current slide
  return {
    transform: `translateX(${baseTranslateX + dragTranslateX.value}%)`,
    transition: dragging.value ? 'none' : `transform ${transitionTime}s ease-in-out` // Disable transition while dragging
  }
})

// Go to the next slide
const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % props.items.length
}

// Go to the previous slide
const prevSlide = () => {
  currentIndex.value = (currentIndex.value - 1 + props.items.length) % props.items.length
}

// Automatic sliding functionality
let intervalId

const startAutoSlide = () => {
  intervalId = setInterval(nextSlide, 3000) // Change slides every 3 seconds
}

const stopAutoSlide = () => {
  clearInterval(intervalId)
}

onMounted(() => {
  startAutoSlide()
})

onBeforeUnmount(() => {
  stopAutoSlide() // Clear interval when component is destroyed
})

// Touch events for sliding
let startX = 0
let deltaX = 0

function onTouchStart(e) {
  stopAutoSlide() // Stop auto-slide on touch start
  transitionTime=0;
  startX = e.touches[0].pageX
  dragging.value = true // Set dragging state
  dragTranslateX.value = 0 // Reset drag translation
}

function onTouchMove(e) {
  if (!dragging.value) return
  deltaX = e.touches[0].pageX - startX
  dragTranslateX.value = (deltaX / window.innerWidth) * 100 // Convert px to percentage
}

function onTouchEnd() {
  dragging.value = false // End dragging
  // Apply a threshold to change slide on swipe
  if (deltaX > 50) {
    prevSlide()
  } else if (deltaX < -50) {
    nextSlide()
  }
  dragTranslateX.value = 0 // Reset drag translation
  startAutoSlide() // Resume auto-slide after touch end
  transitionTime=0.5;
  deltaX = 0
}
</script>

<template>
  <div class="relative w-full max-h-[200px] overflow-hidden">
    <!-- Wrapper for sliding images -->
    <div
      class="flex"
      :style="slideStyle"
      ref="slider"
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
    >
      <div v-for="(image, index) in items" :key="index" class="flex-shrink-0 w-full">
        <img
          :src="image + '?w=400&h=300'"
          alt="Slide image"
          class="w-full h-auto object-contain px-2"
          @click="$emit('show', index)"
        />
      </div>
    </div>

    <!-- Previous Button (Arrow Left) -->
    <button
      class="absolute top-1/2 left-4 -translate-y-1/2 bg-indigo-800 text-white w-8 h-8 rounded-full opacity-60 hover:opacity-100"
      @click.prevent="prevSlide"
    >
      <i class="fa-solid fa-arrow-left"></i>
    </button>

    <!-- Next Button (Arrow Right) -->
    <button
      class="absolute top-1/2 right-4 -translate-y-1/2 bg-indigo-800 text-white w-8 h-8 rounded-full opacity-60 hover:opacity-100"
      @click.prevent="nextSlide"
    >
      <i class="fa-solid fa-arrow-right"></i>
    </button>
  </div>
</template>

<style scoped>
/* Custom styling as needed */
</style>
